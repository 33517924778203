import React, { useState, useEffect, useRef } from 'react';
// import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
// import { FileUpload } from 'primereact/fileupload';
// import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
// import { InputTextarea } from 'primereact/inputtextarea';
// import { RadioButton } from 'primereact/radiobutton';
// import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import { Password } from 'primereact/password';
// import { Link } from 'react-router-dom';
import axios from "axios";
import { MultiSelect } from 'primereact/multiselect';

    

const Group = () => {
    const toast = useRef(null);
    const [fname,setFname] = useState('');
    const [roles,setRoles] = useState([]);
const [loadz,setLoadz] = useState(false);
const [prod,setProd] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [selectedRoles,setSelectedRoles] = useState([]);
    const handleSubmit=async (e)=>{
        e.preventDefault()
setLoadz(true);

const rlz = selectedRoles.map((it,i)=>{
return it.id

})
const newz = rlz.join(',');
// console.log(newz,fname)
try {
    var formdata = new FormData();
    
    formdata.append('group_name', fname);
    formdata.append('roles', newz);

var config = {
method: 'post',
url: 'https://mtn-services.verxid.site/live/create-group',
data : formdata
};
  const res = await axios(config);
  console.log(res)
  const stat = res.data.mtn.status;
  if(stat === 1){
    setLoadz(false)
      setFname('');
    hideDialog()
      toast.current?.show({severity:'success', summary: 'Group Created Successfully.', detail:res.data.mtn.message, life: 1500});
  }

  else{
    setLoadz(false)
    toast.current?.show({severity:'error', summary: 'Group Not Created.', detail:res.data.mtn.message, life: 1500});
      
  }

} catch (error) {
    setLoadz(false)
    toast.current?.show({severity:'error', summary: 'Error Creating Group.', detail:'Please retry,', life: 1500});
 
}
          
           
        }

    
    const hideDialog = () => {
      
        setProductDialog(false);
        setLoadz(false)
    }
    const openNew = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        setProductDialog(true);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New Group" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

 



    const agentStatus=(rowData)=>{
        
const attr=rowData.roles.map((item,i)=>{
return item.rolename;

})
const newz = attr.join(',')
return <p className='w-6'>
    {newz}
</p>;
}
    


    useEffect(() => {
        const getDatz=async ()=>{

            try {
                var config = {
                    method: 'get',
                    url: 'https://mtn-services.verxid.site/live/get-group',
                    
                  };
                      const res = await axios(config);

                      setProd(res.data.mtn.roles)
            } catch (error) {
                console.log(error)
            }

        }
                getDatz();
    }, []); 


    useEffect(() => {
        const getRolz=async ()=>{

            try {
                var config = {
                    method: 'get',
                    url: 'https://mtn-services.verxid.site/live/get-roles',
                    
                  };
                      const res = await axios(config);

                      setRoles(res.data.mtn.roles)
            } catch (error) {
                console.log(error)
            }

        }
                getRolz();
    }, []); 
    return (
        <div className="layout-dashboard">
            <div className="grid">
            <div className="col-12 ">
                <div className="card card-w-title global-sales p-fluid">
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                       
                        <DataTable 
                        value={prod}
                         paginator rows={5} className="p-datatable-products"
                        // globalFilter={globalFilter}
                         emptyMessage="No user-role found." 
                        // header={header}
                        responsiveLayout="scroll"
                        >
                            <Column field="group_name"  header="Name"></Column>
                            {/* <Column field="lastname" sortable header="Lastname"></Column>
                            <Column field="email" header="Email"></Column>
                            <Column field="phone" header="Phone"></Column> */}
                            <Column field="" header="Attributes" body={agentStatus}></Column>
                
                            
    
                        </DataTable>


                        <Dialog visible={productDialog} style={{ width: '450px' }} header="New User" modal className="p-fluid" 
                        // footer={productDialogFooter} 
                        onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <form onSubmit={handleSubmit}>

                        <div className="field">
                            <label htmlFor="name">Group name</label>
                            <InputText id="fname"   required  className='' onChange={(e)=>setFname(e.target.value)} />
                           
                        </div>
                        <div className="field">
                        <MultiSelect  value={selectedRoles}  options={roles} onChange={(e) => setSelectedRoles(e.value)} optionLabel="rolename" placeholder="Select attribute" display="chip" />
                        </div>


                        <Button label="Create User" type='submit'  icon="pi pi-check" loading={loadz} />
                        </form>
                    </Dialog>
                    </div>
                </div>
                </div>
                <Toast ref={toast} position="top-center" />
                </div>
    )


}

export default Group;

