import React,{useState,useEffect,useRef} from 'react';
import {Chip} from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
import axios from "axios";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
const Thres = () => {
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const [score, setScore] = useState(0);
    const [nwscore, setnwScore] = useState(0);

    const toast = useRef(null);
    const update=async()=>{
        setLoadingUpdate(true);

    try {
              const res = await axios.get(`https://mtn-services.verxid.site/live/update-threshold?score=${nwscore}`);
    // console.log(res.data.mtn.status)
              if(res.data.mtn.status===1){
                toast.current?.show({severity:'success', summary: 'score update successful.', detail:'', life: 2500});
                setLoadingUpdate(false);
                setnwScore(0)
              }
            } catch (error) {
                toast.current?.show({severity:'error', summary: 'score update failed.', detail:'', life: 2500});
                setLoadingUpdate(false);
                setnwScore(0);
             console.log(error)
            }

    }
    useEffect(() => {
            const getThres=async ()=>{
                const statz = await axios.get(`https://mtn-services.verxid.site/live/threshold`);
                // console.log(statz.data.mtn.score)
            setScore(statz.data.mtn.score);
           
            }
                    getThres();
        
        }, [nwscore])
    return (
        <div className="grid">
            <div className="col-12">
            <div className="card">
                    
                    <div className="surface-0">
                        <div className="font-medium text-3xl text-900 mb-3">Threshold score</div>
                        {/* <div className="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div> */}
                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Threshold score</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{score&&score}</div>
                                </li>

                                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Actions</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <InputText placeholder="Score" onChange={(e)=>setnwScore(e.target.value)} />

            <Chip template={<Button label="Update Threshold" className="p-button-secondary p-button-text" loading={loadingUpdate} onClick={update}/>} className="mr-2"/>
            </div>
        </li>
    </ul>
    </div>
            </div>
        </div>
        <Toast ref={toast} position="top-center" />
        </div>
    );
  
}

export default Thres