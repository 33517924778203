import React,
{useEffect,useState,useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import {Chip} from 'primereact/chip';
import axios from "axios";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
const Single = () => {
const {id} = useParams();
const [sing, setSing] = useState({});
const [loading1, setLoading1] = useState(false);
const [loadingMax, setLoadingMax] = useState(false);
const [loadingNin, setLoadingNin] = useState(false);

const [loadingFin, setLoadingFin] = useState(false);


const [loadingOwcLeft, setLoadingOwcLeft] = useState(false);
const [loadingOwcRight, setLoadingOwcRight] = useState(false);



const [loadingClm, setLoadingClm] = useState(false);
const [loadingSum, setLoadingSum] = useState(false);
const [loadingOwc, setLoadingOwc] = useState(false);
const [loadingOwcNin, setLoadingOwcNin] = useState(false);
const [loadingDoc, setLoadingDoc] = useState(false);
const [loadingPass, setLoadingPass] = useState(false);
const [loadingVisa, setLoadingVisa] = useState(false);
const[showNin,setShowNin] = useState(false);
const [selectedFinger, setSelectedFinger] = useState('');
const [selectedFingerAns, setSelectedFingerAns] = useState('');
const[userz,setUserz] = useState({});
const toast = useRef(null);
const cities = [
    {name: 'leftthumb'},
    {name: 'leftindexfinger'},
    {name: 'leftmiddlefinger'},
    {name: 'leftringfinger'},
    {name: 'leftlittlefinger'},
    {name: 'rightthumb'},
    {name: 'rightindexfinger'},
    {name: 'rightmiddlefinger'},
    {name: 'rightringfinger'},
    {name: 'rightlittlefinger'}
];




const onCityChange = (e) => {
    setSelectedFinger(e.value);
    setSelectedFingerAns(e.value.name);
} 
const maxCheck=async()=>{

    setLoadingMax(true);
  try {
var config = {
    method: 'get',
    url: `https://mtn-services.verxid.site/live/max_4_check?nin=${sing?.nin}`,
    
  };
      const res = await axios(config);
      console.log(res)
      const news=JSON.parse(res.data.substring(res.data.indexOf('{'),res.data.length))
      
      const stat = news.mtn.status;
    //   console.log(news.mtn.data.photo)
    console.log(stat)
      if(stat === 1){
        toast.current?.show({severity:'success', summary: 'Max 4 check passed.', detail:'', life: 3000});
          setLoadingMax(false)
      }

      else{
        toast.current?.show({severity:'error', summary: 'Max 4 check failed', detail:'', life: 3000});
        setLoadingMax(false)
      }
    
    } catch (error) {
        toast.current?.show({severity:'error', summary: 'Max 4 check failed.', detail:'please try again', life: 2500});
        setLoadingMax(false)
    //  console.log(error)
    }



}


const verNin=async()=>{
    setLoadingNin(true)
    try {
var config = {
    method: 'get',
    url: `https://mtn-services.verxid.site/live/verify_nin?nin=${sing?.nin}`,
    // 90108210801
    // url: `https://api.verxid.site/mtn/verify_nin?nin=90108210801`,
  };
      const res = await axios(config);
      const news=JSON.parse(res.data.substring(res.data.indexOf('{'),res.data.length))
      

      const stat = news.mtn.status;
    //   console.log(news.mtn.data.photo)
      if(stat === 1){
          setShowNin(true);
          setUserz(news.mtn.data)
          setLoadingNin(false)
      }

      else{
        toast.current?.show({severity:'error', summary: 'NIN not verified.', detail:'', life: 2500});
        setLoadingNin(false)
      }
           
    } catch (error) {
        toast.current?.show({severity:'error', summary: 'NIN not verified.', detail:'please try again', life: 2500});
        setLoadingNin(false)
     console.log(error)
    }

    
}


const clm=async()=>{
setLoadingClm(true);

try {

    var formdata = new FormData();
        
        formdata.append('sim_serial', sing?.sim_serial);
        formdata.append('msisdn', sing?.msisdn);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('agentemail', sing?.agentemail);
         formdata.append('agentname', sing?.agentname);
         formdata.append('refid', sing?.processing_number);

         formdata.append('nin', sing?.nin);
         formdata.append('place_birth', sing?.place_birth);
         formdata.append('mother_maiden', sing?.mother_maiden);
         formdata.append('email', sing?.email);
         formdata.append('passport_num', sing?.passport_num);


         formdata.append('passport_startdate', sing?.passport_startdate);
         formdata.append('passport_enddate', sing?.passport_enddate);
         formdata.append('country', sing?.country);
         formdata.append('firstname', sing?.firstname);
         formdata.append('lastname', sing?.lastname);

         formdata.append('gender', sing?.gender);
         formdata.append('agentMSISDN', sing?.agentphone);
         formdata.append('full_date', sing?.full_date);
         formdata.append('birth_date', sing?.birth_date);
         formdata.append('address', sing?.address);

         formdata.append('consent_type', sing?.consent_type);
         formdata.append('consent_sub_type', sing?.consent_sub_type);
         formdata.append('lga', sing?.lga);
         formdata.append('type', sing?.type);
         
         formdata.append('overideface', sing?.overideface);
         formdata.append('bypassoveride', sing?.bypassoveride);
         formdata.append('face_score', sing?.face_score);
         formdata.append('foreignerType', sing?.foreignerType);
         formdata.append('visa_startdate', sing?.visa_startdate);
         formdata.append('visa_enddate', sing?.visa_enddate);

         formdata.append('countrycode', sing?.countrycode);
         formdata.append('threshold', sing?.threshold);


 var config = {
     method: 'post',
    url: 'https://mtn-services.verxid.site/live/submit_clm_sim',
     data : formdata
   };
          const res = await axios(config);
          const stat = res.data.mtn.status;
       
          if(stat === 1){
            
            toast.current?.show({severity:'success', summary: 'CLM submission successful.', detail:'', life: 2500});
              setLoadingClm(false);
          }
             

          else{
            toast.current?.show({severity:'error', summary: 'CLM submit failed.', detail:res.data.mtn.message, life: 2500});
            setLoadingClm(false);
          }
        
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'CLM submit failed.', detail:'', life: 2500});
            setLoadingClm(false);
         console.log(error)
        }


    }

   


const owc= async()=>{
    setLoadingOwc(true);
// console.log('owc');
var newms = sing.msisdn || sing.sim_serial;
try {

    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('file', sing?.face);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_photo',
     data : formdata
   };
          const res = await axios(config);

          
        //   const stat = res.data.mtn.status;
    //    console.log(res.status)
          if(res.status){
            
            toast.current?.show({severity:'success', summary: 'OWC Face push successful.', detail:'', life: 2500});
            setLoadingOwc(false);
          }
    
        
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC Face push failed.', detail:'', life: 2500});
            setLoadingOwc(false);
         console.log(error)
        }
   

}



const owcleft= async()=>{
    setLoadingOwcLeft(true);
// console.log('owc');
var newms = sing.msisdn || sing.sim_serial;
try {

    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('file', sing?.lefthand_img);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_lefthandimg',
     data : formdata
   };
          const res = await axios(config);

          
        //   const stat = res.data.mtn.status;
       console.log(res.status)
       console.log(res);
          if(res.status){
            
            toast.current?.show({severity:'success', summary: 'OWC left hand push successful.', detail:'', life: 2500});
            setLoadingOwcLeft(false);
          }
    
        
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC left hand push failed.', detail:'', life: 2500});
            setLoadingOwcLeft(false);
         console.log(error)
        }
   

}

const owcright= async()=>{
    setLoadingOwcRight(true);
// console.log('owc');
var newms = sing.msisdn || sing.sim_serial;
try {

    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('file', sing?.righthand_img);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_righthandimg',
     data : formdata
   };
          const res = await axios(config);

          
        //   const stat = res.data.mtn.status;
       console.log(res.status)
       console.log(res);
          if(res.status){
            
            toast.current?.show({severity:'success', summary: 'OWC right hand push successful.', detail:'', life: 2500});
            setLoadingOwcRight(false);
          }
    
        
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC right hand push failed.', detail:'', life: 2500});
            setLoadingOwcRight(false);
         console.log(error)
        }
   

}






const owcNin= async()=>{
    setLoadingOwcNin(true);
// console.log('owc');
var newms = sing.msisdn || sing.sim_serial;
try {

    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('file', sing?.nin_face);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_nimc_portrait',
     data : formdata
   };
          const res = await axios(config);

          

        //   const stat = res.data.mtn.status;
    //    console.log(res.status)
          if(res.status){
            
            toast.current?.show({severity:'success', summary: 'OWC Nimc Face push successful.', detail:'', life: 2500});
            setLoadingOwcNin(false);
          }
    
        
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC Nimc Face push failed.', detail:'', life: 2500});
            setLoadingOwcNin(false);
         console.log(error)
        }
   

}

const doc=async()=>{
setLoadingDoc(true);
var newms = sing.msisdn || sing.sim_serial;
try {
    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('file', sing?.consent_file);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_consent',
     data : formdata
   };
   
          const res = await axios(config);

          if(res.status){
            toast.current?.show({severity:'success', summary: 'OWC Consent data push successful.', detail:'', life: 2500});
            setLoadingDoc(false);
          }
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC Consent data push successful.', detail:'', life: 2500});
            setLoadingDoc(false);
         console.log(error)
        }
  
}


const sum= async()=>{
    setLoadingSum(true);
try {
    var formdata = new FormData();
        
        formdata.append('msisdn', sing?.msisdn);
        formdata.append('sim_serial', sing?.sim_serial);
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         formdata.append('agentemail', sing?.agentemail);
         formdata.append('consent_type', sing?.consent_type);
         formdata.append('consent_sub_type', sing?.consent_sub_type);
         formdata.append('firstname', sing?.firstname);
         formdata.append('lastname', sing?.lastname);


 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_summary',
     data : formdata
   };
          const res = await axios(config);
console.log(res)
          if(res.status){
            toast.current?.show({severity:'success', summary: 'OWC Summary data push successful.', detail:'', life: 2500});
            setLoadingSum(false);
          }
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC Summary data push failed.', detail:'', life: 2500});
            setLoadingSum(false);
         console.log(error)
        }
}


const pass=async()=>{
    setLoadingPass(true);
    var newms = sing.msisdn || sing.sim_serial;
try {
    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
        
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         formdata.append('file', sing?.passport_page);
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_passport',
     data : formdata
   };
          const res = await axios(config);
console.log(res)
          if(res.status){
            toast.current?.show({severity:'success', summary: 'OWC passport push successful.', detail:'', life: 2500});
            setLoadingPass(false);
          }
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC passport push failed.', detail:'', life: 2500});
            setLoadingPass(false);
         console.log(error)
        }
    console.log('pass')

}

   

const visa= async()=>{
    setLoadingVisa(true);
    var newms = sing.msisdn || sing.sim_serial;
try {
    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
        
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         formdata.append('file', sing?.visa_page);
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_visa',
     data : formdata
   };
          const res = await axios(config);
console.log(res)
          if(res.status){
            toast.current?.show({severity:'success', summary: 'OWC visa push successful.', detail:'', life: 2500});
            setLoadingVisa(false);
          }
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC visa push failed.', detail:'', life: 2500});
            setLoadingVisa(false);
         console.log(error)
        }
    // console.log('pass')

}

const fin=async()=>{

    setLoadingFin(true);
    var newms = sing.msisdn || sing.sim_serial;
try {
    var formdata = new FormData();
        
        formdata.append('msisdn', newms);
        
         formdata.append('deviceid', sing?.deviceid);
         formdata.append('appid', sing?.appid);
         formdata.append('refid', sing?.processing_number);
         formdata.append('nin', sing?.nin);
         formdata.append('capturedate', sing?.full_date);
         formdata.append('file', sing[`${selectedFinger}`]);
         formdata.append('fingerposition', selectedFingerAns);
 var config = {
     method: 'post',
    url: 'https://contactless.mtnonline.com/live/owc-submit_customer_fingers',
     data : formdata
   };
          const res = await axios(config);
console.log(res)
          if(res.status){
            toast.current?.show({severity:'success', summary: 'OWC finger push successful.', detail:'', life: 2500});
            setLoadingFin(false);
          }
        } catch (error) {
            toast.current?.show({severity:'error', summary: 'OWC finger push failed.', detail:'', life: 2500});
            setLoadingFin(false);
         console.log(error)
        }


}
useEffect(() => {
setLoading1(true);

    const getSing=async ()=>{
        const statz = await axios.get(`https://mtn-services.verxid.site/live/viewSingle?id=${id}`);
        console.log(statz.data.mtn)
    setSing(statz.data.mtn[0]);
    setLoading1(false)
    }
            getSing()

}, [id])

console.log(selectedFinger)

if(loading1){
    return(
        <ProgressSpinner/>
    )
}
    return (
       
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    
<div className="surface-0">
    <div className="font-medium text-3xl text-900 mb-3">Single Registration</div>
    {/* <div className="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div> */}
    <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">msisdn</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.msisdn}</div>
          
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Sim serial</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.sim_serial}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">NIN</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.nin}</div>
          
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Mother maiden name</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.mother_maiden}</div>
           
        </li>

        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Alternative phone</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.alt_phone}</div>
           
        </li>
        
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Email</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.email}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Place Of Birth</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.place_birth}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Agent Email</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{sing?.agentemail}</div>
           
        </li>
       
        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">Actions</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <Chip template={<Button label="Verify NIN" className="p-button-secondary p-button-text" loading={loadingNin} onClick={verNin}/>} className="mr-2"/>
                
                <Chip template={<Button label="Max 4 Check" className="p-button-info p-button-text" loading={loadingMax} onClick={maxCheck} />} className="mr-2"/>

                <Chip template={<Button label="Push to CLM" className="p-button-success p-button-text" loading={loadingClm} onClick={clm} />} className="mr-2"/>

                <Chip template={<Button label="Push OWC face" className="p-button-warning p-button-text" loading={loadingOwc} onClick={owc} />} className="mr-2 my-2"/>
                <Chip template={<Button label="Push OWC NIN face" className="p-button-info p-button-text" loading={loadingOwcNin} onClick={owcNin} />} className="mr-2 my-2"/>


                <Chip template={<Button label="Push OWC left hand" className="p-button-secondary p-button-text" loading={loadingOwcLeft} onClick={owcleft} />} className="mr-2 my-2"/>
                <Chip template={<Button label="Push OWC right hand" className="p-button-info p-button-text" loading={loadingOwcRight} onClick={owcright} />} className="mr-2 my-2"/>


                {
    sing?.consent_type ==='Consent_Form' && (
                <Chip template={<Button label="Push OWC Consent" className="p-button-danger p-button-text" loading={loadingDoc} onClick={doc} />} className="mr-2 my-2"/>
   )}
                <Chip template={<Button label="Push OWC Summary" className="p-button-primary p-button-text" loading={loadingSum} onClick={sum} />} className="mr-2 my-2"/>
{
    sing?.type ==='fx_new' && (
    <><Chip template={<Button label="Push OWC Passport" className="p-button-danger p-button-text" loading={loadingPass} onClick={pass} />} className="mr-2 my-2"/> 

               
               <Chip template={<Button label="Push OWC Visa" className="p-button-info p-button-text" loading={loadingVisa} onClick={visa} />} className="mr-2 my-2"/>
               </>
    )
            }
             
<div>

<Dropdown  options={cities}  value={selectedFinger} onChange={onCityChange} optionLabel="name" placeholder="Select finger" />
<Chip template={<Button label="Push OWC Finger" className="p-button-danger p-button-text" loading={loadingFin} onClick={fin} />} className="mr-2 my-2"/>
</div>
                  {/*<Chip template={<Button label="Push OWC Consent" className="p-button-danger p-button-text" loading={loadingDoc} onClick={doc} />} className="mr-2 my-2"/> */}

            </div>
        </li>
    </ul>
</div>
    
                </div>
                <Toast ref={toast} position="top-center" />

                {showNin&&( <div className="card">
                    
                    <img alt='' src={`data:image/png;base64,${
                        userz?.photo
                    }`
                    }/>
                    <ul className="list-none p-0 m-0">
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">firstname</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{userz?.firstName}</div>
          
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">lastname</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{userz?.lastName}</div>
           
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">middlename</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{userz?.middleName}</div>
          
        </li>
        <li className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap">
            <div className="text-500 w-6 md:w-2 font-medium">email</div>
            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{userz?.email}</div>
           
        </li>
        </ul>
                    </div>)}
            </div>
        </div>
    );
}





export default Single;