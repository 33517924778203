import React, { useState, useEffect, useRef } from "react";
// import { classNames } from 'primereact/utils';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
// import { FileUpload } from 'primereact/fileupload';
// import { Rating } from 'primereact/rating';
import { Toolbar } from "primereact/toolbar";
// import { InputTextarea } from 'primereact/inputtextarea';
// import { RadioButton } from 'primereact/radiobutton';
// import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Link } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import axios from "axios";

const User = () => {
    const toast = useRef(null);
    const [fname, setFname] = useState("");
    const [uname, setUname] = useState("");
    const [lname, setLname] = useState("");
    const [em, setEm] = useState("");
    const [pwd, setPwd] = useState("");
    const [ph, setPh] = useState("");
    const [loadz, setLoadz] = useState(false);
    const [prod, setProd] = useState([]);
    const [productDialog, setProductDialog] = useState(false);

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
           
        });

        setGlobalFilterValue1("");
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        console.log(value);
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;
        console.log(_filters1);
        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    };
    const clearFilter1 = () => {
        initFilters1();
    };
    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
                {/* <div className="table-header-container">All Onboarded Users</div> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const header1 = renderHeader1();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadz(true);
        if (!fname || !lname || !em || !pwd || !ph) {
            toast.current?.show({ severity: "error", summary: "Empty Field", detail: "Please fill all form input.", life: 1500 });
            setLoadz(false);
        } else {
            try {
                var formdata = new FormData();

                formdata.append("firstname", fname);
                formdata.append("phone", ph);
                formdata.append("lastname", lname);
                formdata.append("password", pwd);
                formdata.append("email", em);
                formdata.append("username", uname);
                formdata.append("face", "");
                formdata.append("document", "");
                formdata.append("leftthumb", "");
                formdata.append("leftindex", "");
                formdata.append("leftmiddle", "");
                formdata.append("leftring", "");
                formdata.append("leftlittle", "");
                formdata.append("rightthumb", "");
                formdata.append("rightindex", "");
                formdata.append("rightmiddle", "");
                formdata.append("rightring", "");
                formdata.append("rightlittle", "");
                formdata.append("shopname", "");
                formdata.append("division", "");

                var config = {
                    method: "post",
                    url: "https://mtn-services.verxid.site/live/create-user",
                    data: formdata,
                };
                const res = await axios(config);
                console.log(res);
                const stat = res.data.mtn.status;
                if (stat === 1) {
                    setLoadz(false);
                    setFname("");
                    setLname("");
                    setEm("");
                    setPh("");
                    setPwd("");
                    hideDialog();
                    toast.current?.show({ severity: "success", summary: "User Created Successfully.", detail: res.data.mtn.message, life: 1500 });
                    window.location.href = "/user";
                } else {
                    setLoadz(false);
                    toast.current?.show({ severity: "error", summary: "User Not Created.", detail: res.data.mtn.message, life: 1500 });
                }
            } catch (error) {
                setLoadz(false);
                toast.current?.show({ severity: "error", summary: "Error Creating User.", detail: "Please retry,", life: 1500 });
            }
        }
    };
    const hideDialog = () => {
        setProductDialog(false);
        setLoadz(false);
    };
    const openNew = () => {
        // setProduct(emptyProduct);
        // setSubmitted(false);
        setProductDialog(true);
    };
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New User" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        );
    };

    const statusBodyTemplate2 = (rowData) => {
        return (
            <Link className={`product-badge font-bold`} to={`/single-user/${rowData.id}`}>
                VIEW
            </Link>
        );
    };

    const agentStatus = (rowData) => {
        let stat;
        if (rowData.status === "1") {
            stat = "Approved";
        } else if (rowData.status === "0") {
            stat = "Pending";
        } else if (rowData.status === "2") {
            stat = "Blacklisted";
        }

        return stat;
    };

    useEffect(() => {
        initFilters1();
    }, []);
    useEffect(() => {
        const getDatz = async () => {
            try {
                var config = {
                    method: "get",
                    url: "https://mtn-services.verxid.site/live/viewAllUsers",
                    headers: {
                        Authorization: "Basic YmFybmtzZm9ydGUtbXRuOjBubHliYXJua3MxMjM=",
                    },
                };
                const res = await axios(config);
                console.log(res.data.mtn[0]);
                setProd(res.data.mtn);
            } catch (error) {
                console.log(error);
            }
        };
        getDatz();
    }, []);
    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 ">
                    <div className="card">
                        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                        <DataTable
                            value={prod}
                            paginator
                            rows={5}
                            className="p-datatable-products"
                            // globalFilter={globalFilter}
                            emptyMessage="No user found."
                            // header={header}
                            header={header1}
                            filters={filters1}
                            
                        >
                            <Column field="firstname" sortable header="Name"></Column>
                            <Column field="lastname" sortable header="Lastname"></Column>
                            <Column field="email" header="Email"></Column>
                            <Column field="phone" header="Phone"></Column>
                            <Column field="" header="Status" body={agentStatus}></Column>
                            <Column field="id" header="Action" body={statusBodyTemplate2} />
                        </DataTable>

                        <Dialog
                            visible={productDialog}
                            style={{ width: "450px" }}
                            header="New User"
                            modal
                            className="p-fluid"
                            // footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                            <form onSubmit={handleSubmit}>
                                <div className="field">
                                    <label htmlFor="name">Firstname</label>
                                    <InputText id="fname" required className="" onChange={(e) => setFname(e.target.value)} />
                                </div>

                                <div className="field">
                                    <label htmlFor="name">Username</label>
                                    <InputText id="uname" required className="" onChange={(e) => setUname(e.target.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="name">Lastname</label>
                                    <InputText id="username" required className="" onChange={(e) => setLname(e.target.value)} />
                                </div>

                                <div className="field">
                                    <label htmlFor="name">email</label>
                                    <InputText id="email" type="email" required className="" onChange={(e) => setEm(e.target.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="phone">Phone</label>
                                    <InputText id="phone" type="number" required className="" placeholder="234**********" onChange={(e) => setPh(e.target.value)} />
                                </div>
                                <div className="field">
                                    <label htmlFor="name">Password</label>
                                    <Password placeholder="Password" className="w-full" onChange={(e) => setPwd(e.target.value)} toggleMask />
                                </div>
                                <Button label="Create User" type="submit" icon="pi pi-check" loading={loadz} />
                            </form>
                        </Dialog>
                    </div>
                </div>
            </div>
            <Toast ref={toast} position="top-center" />
        </div>
    );
};

export default User;
